import React from 'react';

const QuantityInput = ({ quantity, setQuantity, initialValue = 1, min = 1, max = 100 }) => {

  const handleQuantityChange = (event) => {
    const newQuantity = parseInt(event.target.value);
    if (!isNaN(newQuantity) && newQuantity >= min && newQuantity <= max) {
      setQuantity(newQuantity);
    }
  };

  return (
    <div className="quantity-input">
      <h5>Quantity Desired</h5>
      <select value={quantity} onChange={handleQuantityChange}>
        {Array.from({ length: max - min + 1 }, (_, index) => min + index).map((value) => (
          <option key={value} value={value}>{value}</option>
        ))}
      </select>
    </div>
  );
};

export default QuantityInput;
