
import { useEffect, useState } from 'react';
import './App.css';
import AddressAutocomplete from './components/AddressInput';
import QuantityInput from './components/QuantityInput';
import { fetchProducts, addToCart } from './lib/FetchProducts';
import RetailerSelection from './components/RetailerSelection';
import CartButton from './components/CartButton';
import LegalAccordion from './components/LegalAccordion';



function App() {
  const [address, setAddress] = useState('');
  const [placeDetails, setPlaceDetails] = useState(null);
  const [selectedRetailer, setSelectedRetailer] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [product, setProduct] = useState(null);
  const [activeCheckout, setActiveCheckout] = useState(false);
  const [cart, setCart] = useState(null);
  const groupID = window.reserveBarGroupId; //"GROUPING-1010427"; // Come from window.


  useEffect(() => {
    // Display place details.
    console.log('Place Details:', placeDetails);
    if(!placeDetails) return;
    fetchProducts(groupID, placeDetails).then((products) => {
      console.log(products);
      setProduct(products);
    });
  }, [placeDetails]);

  useEffect(() => {
    // We have selected a retailer!
    console.log("Selected Retailer:", selectedRetailer);
    if(selectedRetailer) {
      setActiveCheckout(true);
    }
  }, [selectedRetailer]);

  const addProductToCart = async () => {
    let updatedCart = null;
    //if(cart) {
      updatedCart = await addToCart(selectedRetailer.variantId, quantity, null);
    // }
    // else {
    //   updatedCart = await addToCart(selectedRetailer.variantId, quantity, cart.id);
    // }
    
    setCart(updatedCart);
    // Save updated cart to local storage.
    localStorage.setItem('reserveBarCart', JSON.stringify(updatedCart));
    localStorage.setItem('reserveBarPlaceDetails', JSON.stringify(placeDetails));

    // Fire an event on the document.
    const event = new CustomEvent('reserveBarCartUpdated', { detail: updatedCart });
    document.dispatchEvent(event);
    //setCartToggle(true);

    console.log("Added product to cart");
  }

  return (
    <div className="app">
      <div className="app-container">
        <AddressAutocomplete address={address} setAddress={setAddress} placeDetails={placeDetails} setPlaceDetails={setPlaceDetails} />
        <QuantityInput quantity={quantity} setQuantity={setQuantity} initialValue={1} min={1} max={10}></QuantityInput>
        {product && <RetailerSelection retailers={product} setSelectedRetailer={setSelectedRetailer} placeDetails={placeDetails}></RetailerSelection>}
        <CartButton addProductToCart={addProductToCart} activeCheckout={activeCheckout} />
        <LegalAccordion></LegalAccordion>
      </div>
      {/* <CartPane cartToggle={cartToggle} setCartToggle={setCartToggle} cart={cart} setCart={setCart} placeDetails={placeDetails}></CartPane> */}
    </div>
  );
}

export default App;
