import React, { useEffect, useState } from 'react';
import Address from './Address';

import { newCart } from '../lib/FetchProducts';

const AddressAutocomplete = ({ address, setAddress, setPlaceDetails }) => {
  const [predictions, setPredictions] = useState([]);
  const [AddressError, setAddressError] = useState(null);

  // If localstorage has a place details object, set it to state.
  useEffect(() => {
    if (localStorage.getItem('reserveBarPlaceDetails')) {
      setAddress(JSON.parse(localStorage.getItem('reserveBarPlaceDetails')).formattedAddress);
      setPlaceDetails(JSON.parse(localStorage.getItem('reserveBarPlaceDetails')));
    }
  }, []);

  const handleInputChange = async (event) => {
    const value = event.target.value;
    setAddress(value);

    if (value.trim() === '') {
      setPredictions([]);
      return;
    }

    try {
      const predictions = await Address.getPredictions({ search: value });

      if (predictions) {
        setPredictions(predictions);
      }
    } catch (error) {
      console.error('Error fetching address predictions:', error);
    }
  };

  const handlePredictionSelection = async (prediction) => {

    setAddress(prediction.description);

    try {
      const placeDetails = await Address.getPlaceDetails({ placeId: prediction.placeId });
      console.log('Selected Place Details:', placeDetails);

      // Check if the address has a street address and does not start with a numeric value
      if (!placeDetails.address1 || placeDetails.address1.trim() === '' || placeDetails.address1.trim() === '' || !/^\d/.test(placeDetails.address1.trim())) {
        setAddressError("Please enter a street address.");
        setPlaceDetails(null);
        return;
      }

      // Save the place details to local storage
      if (localStorage.getItem('reserveBarPlaceDetails')) {
        if (placeDetails.formattedAddress != JSON.parse(localStorage.getItem('reserveBarPlaceDetails')).formattedAddress) {
          console.log("Address has changed. Clearing cart.");
          localStorage.setItem('reserveBarPlaceDetails', JSON.stringify(placeDetails));

          // Clear cart.
          const cart = await newCart();

          // Set the local storage cart.
          localStorage.setItem('reserveBarCart', JSON.stringify(cart));

        }
      }
      setPlaceDetails(placeDetails);
    } catch (error) {
      console.error('Error fetching place details:', error);
    }
    setAddressError(null);
    setPredictions([]);
  };

  return (
    <div className="address-container">
      <h5>Enter and Select Address</h5>
      <div className='error'>{AddressError}</div>
      <input
        type="text"
        value={address}
        onChange={handleInputChange}
        placeholder="Enter an address"
        className="address-input"
      />
      <ul className="address-predictions">
        {predictions.map((prediction) => (
          <li key={prediction.placeId} onClick={() => handlePredictionSelection(prediction)}>
            {prediction.description}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AddressAutocomplete;
