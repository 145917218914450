// A component which shows some basic legal information. This component exposes a simple accordion display toggle.


import React, { useState } from 'react';

const LegalAccordion = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="legal-accordion">
      <h5 className="toggle-button" onClick={toggleAccordion}>
        <span>Legal</span>
        {isOpen ? <span>-</span> : <span>+</span>}
      </h5>
      {isOpen && (
        <div className="legal-info">
          <p>
            Enjoy responsibly. Michter’s is not a licensed beverage alcohol retailer. All alcohol orders are sold and shipped by licensed retailers in your state on the reservebar.com network of merchants. Michter’s is not involved with the sale or delivery of alcohol products.  Reservebar.com is operated by ReserveBar, an independent third-party. The availability of alcohol products for delivery to any customer, and the acceptance and fulfillment of any orders, is the independent decision and responsibility of ReserveBar and its licensed retailer network.  
          </p>
        </div>
      )}
    </div>
  );
};

export default LegalAccordion;
