import Liquid from '@liquidcloud/sdk'

const Address = {
  getPredictions: async (searchObj) => {
    const liquid = await Liquid({ clientId: '03c19a9d54f038709d286b66ba391c83', env: 'production' });

    const predictions = await liquid.address({
      search: searchObj.search,
    });

    return predictions;
  },
  getPlaceDetails: async (placeObj) => {
    const liquid = await Liquid({ clientId: '03c19a9d54f038709d286b66ba391c83', env: 'production' });

    const placeDetails = await liquid.address({
      placeId: placeObj.placeId,
    });


    return placeDetails;
  },
}

export default Address