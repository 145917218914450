import React, { useEffect, useState } from 'react';

const RetailerSelection = ({retailers, setSelectedRetailer, placeDetails}) => {
  const [shipRetailers, setShipRetailers] = useState([]);
  const [onDemandRetailers, setOnDemandRetailers] = useState([]);
  const [selectedRetailerId, setSelectedRetailerId] = useState(null);
  const [noResults, setNoResults] = useState(false);
  const [loading, setLoading] = useState(false);

  const loggerAPIKey = "2793fae5f4af6d0b7a99f4ed442aa5778f372c0012a449ec9cb6f26259a20158";

  const logEmptyAddress = (placeDetails) => {
     //We want to record the address the user entered to the database
     fetch('/wp-json/reservebar/v1/save-empty-address', {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'X-Rp-API-Key': loggerAPIKey
      },
      body: JSON.stringify({ input_address: placeDetails.formattedAddress, product: retailers[0]['name'] }),
    }).catch((error) => console.error('Error:', error));
  }

  const logFullAddress = (placeDetails, onDemandRetailers, shipRetailers) => {
    console.log("Logging Full Address")
    console.log(onDemandRetailers.length);
    console.log(shipRetailers.length);
    //We want to record the address the user entered to the database
    fetch('/wp-json/reservebar/v1/save-full-address', {
     method: 'POST',
     headers: {
         'Content-Type': 'application/json',
         'X-Rp-API-Key': loggerAPIKey
     },
     body: JSON.stringify({ input_address: placeDetails.formattedAddress, product: retailers[0]['name'], count_ondemand: onDemandRetailers.length, count_shipped: shipRetailers.length}),
   }).catch((error) => console.error('Error:', error));
 }



  useEffect(() => {
    setLoading(true);
    console.log("Retailers:", retailers)
    if (!retailers) {
      setNoResults(true);
      logEmptyAddress(placeDetails);
      setLoading(false);
      return;
    }

    if(retailers[0].variants.length === 0) {
      setNoResults(true);
      logEmptyAddress(placeDetails);
      setLoading(false);
      return;
    }

    const variants = retailers[0].variants[0].retailers;

    const shippedRetailers = [];
    const demandRetailers = [];

    console.log("Retailers:", retailers)
    console.log("Address:", placeDetails.state)
    console.log("Variants:", variants)
    
    variants.forEach((variant) => {
      if (variant.type === "shipped" && variant.retailer.address.stateName === placeDetails.state) {
        shippedRetailers.push(variant);
      } 
      if (variant.type === "onDemand" && variant.retailer.address.stateName === placeDetails.state) {
        demandRetailers.push(variant);
      }
    });

    console.log("Shipped:", shippedRetailers)
    console.log("OnDemand:", demandRetailers)

    if(shippedRetailers.length === 0 && demandRetailers.length === 0) {
      setNoResults(true);
      logEmptyAddress(placeDetails);
      setLoading(false);
      return;
    }

    setShipRetailers(shippedRetailers.slice(0, 3)); // Display up to 3 shipped retailers
    setOnDemandRetailers(demandRetailers.slice(0, 3)); // Display up to 3 onDemand retailers
    logFullAddress(placeDetails, demandRetailers, shippedRetailers);

    setNoResults(false);
    setLoading(false);
  }, [retailers]);

  const selectRetailer = (retailer) => {
    setSelectedRetailer(retailer);
    setSelectedRetailerId(retailer.variantId);
  }
  
  if(loading) {
    return (
      <div>Searching for retailers...</div>
    )
  }

  if (noResults) {
    return(
      <div className="no-retailers">Sorry, there are currently no available options in your area.</div>
    )
  }

  // console.log("Ship Retailers:", onDemandRetailers)
  return (
    <div className="retailer-input">
      {onDemandRetailers.length > 0 &&
        <>
          <h5>Local Delivery</h5>
          {onDemandRetailers.map((retailer) => (
            <div 
              className={`retailer-wrapper ${selectedRetailerId === retailer.variantId ? 'selected' : ''}`}
              onClick={() => selectRetailer(retailer)}
              key={retailer.variantId}
            >
              <div className="retailer">
                <p className="retailer-name">Fullfilled by: {retailer.retailer.name}</p>
                <div className="retailer-address">{retailer.retailer.address.address1}, {retailer.retailer.address.city}, {retailer.retailer.address.stateName} {retailer.retailer.address.zipCode}</div>
                <div className="retailer-details">
                  <p>{retailer.shippingMethod.deliveryExpectation}</p>
                  <p>${retailer.price}</p>
                </div>
                </div>
            </div>
          ))}
        </>
      }
      {shipRetailers.length > 0 && 
        <>
          <h5>Standard Shipping</h5>
          {shipRetailers.map((retailer) => (
            <div 
              className={`retailer-wrapper ${selectedRetailerId === retailer.variantId ? 'selected' : ''}`}
              onClick={() => selectRetailer(retailer)}
              key={retailer.variantId}
            >
              <div className="retailer">
                <p className="retailer-name">Fullfilled by: {retailer.retailer.name}</p>
                <div className="retailer-address">{retailer.retailer.address.address1}, {retailer.retailer.address.city}, {retailer.retailer.address.stateName} {retailer.retailer.address.zipCode}</div>
                <div className="retailer-details">
                  <p>{retailer.shippingMethod.deliveryExpectation}</p>
                  <p>${retailer.price}</p>
                </div>
              </div>
            </div>
          ))}
        </>
      }
    </div>
  );
};

export default RetailerSelection;
