import { useState } from "react";

const CartItem = ({item, removeItem, updateItem}) => {

    const qtyInStock = item.product.inStock;
    const [qty, setQty] = useState(item.quantity);

    // Generate an array of quantity options from 1 to qtyInStock
    const quantityOptions = Array.from({ length: qtyInStock }, (_, i) => i + 1);

    const handleQuantityChange = (event) => {
        const selectedQty = parseInt(event.target.value, 10);
        setQty(selectedQty);
        updateItem(item, selectedQty); 
    };

    return (
        <>        
        <div key={item.id} className="cart-item">
            <div className="cart-item-image">
                <img src={item.product.imageUrl} alt={item.productGrouping.name} />
            </div>
            <div className="cart-item-details">
                <div className="cart-item-header">
                    <div className="cart-item-name">
                        <h3>{item.productGrouping.name}</h3>
                        <p className="btnRemove" onClick={() => removeItem(item)}>✕</p>
                    </div>
                    <h5>{item.product.shortVolume}</h5>
                </div>
                <div className="cart-item-price">
                    <div className="quantity-dropdown">
                        {/* <label htmlFor={`quantity-${item.id}`}>Quantity:</label> */}
                        <select
                            id={`quantity-${item.id}`}
                            value={qty}
                            onChange={handleQuantityChange}
                        >
                            {quantityOptions.map((quantity) => (
                            <option key={quantity} value={quantity}>
                                {quantity}
                            </option>
                            ))}
                        </select>
                    </div>
                    <p>${item.product.price}</p>
                </div>
            </div>
            </div>
            <div className="cart-item-fullfiller">
                <div>Fullfilled by: {item.retailerName}</div>
                <div>{item.deliveryExpectation}</div>
            </div>
    </>

    );
}

export default CartItem;