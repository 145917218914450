import Liquid from '@liquidcloud/sdk';

const fetchProducts = async (grouping, address) => {
    // This "clientId" is automatically populated with your own and unique partner token, use it for your own implementation
    const liquid = await Liquid({clientId: '03c19a9d54f038709d286b66ba391c83', env: 'production'});
    
    console.log(address.formattedAddress);

    const products = await liquid.product({
      // Top Sellers in different categories and suppliers
      //ids: ["GROUPING-1010427", "GROUPING-827558", "GROUPING-33233", "GROUPING-42701"],
      //ids: ["GROUPING-1010427"],
      ids: [grouping],
      latitude: address.latitude,
      longitude: address.longitude
    });
    return products;
}


// Creats a new cart.
const newCart = async () => {
  const liquid = await Liquid({ clientId: '03c19a9d54f038709d286b66ba391c83', env: 'production'});

  const cart = await liquid.cart();

  const cartItems = cart.cartItems;

  // Loop through all cart items and set qty to 0.
  cartItems.forEach(cartItem => {
    cartItem.quantity = 0;
  });

  // Update the cart with the new cart items.
  const updatedCart = await liquid.cart({
    // id: cart.id,
    cartItems: cartItems
  });

  return updatedCart;
}

const addToCart = async (variantId, quantity, id) => {

  const liquid = await Liquid({ clientId: '03c19a9d54f038709d286b66ba391c83', env: 'production'});

  const cartObj = {
    cartItems: [{
      variantId: variantId,
      quantity: quantity,
    }]
  }

  // Do we have an exsitng cart?
  if(id) {
    cartObj.id = id;
  }

  const cart = await liquid.cart(cartObj);

  return cart;
}

const removeFromCart = async (variantId,  id) => {

  const liquid = await Liquid({ clientId: '03c19a9d54f038709d286b66ba391c83', env: 'production'});

  const cartObj = {
    cartItems: [{
      variantId: variantId,
      quantity: 0,
    }]
  }

  // Do we have an exsitng cart?
  if(id) {
    cartObj.id = id;
  }

  const cart = await liquid.cart(cartObj);

  return cart;
}

const getCart = async (variantId,  id) => {

  const liquid = await Liquid({ clientId: '03c19a9d54f038709d286b66ba391c83', env: 'production'});

  const cart = await liquid.cart();

  return cart;
}



const getCheckoutUrl = async (cartId, shiptToAddress) => {
  const liquid = await Liquid({ clientId: '03c19a9d54f038709d286b66ba391c83', env: 'production' });

  const checkout = await liquid.checkout({
    cartId: cartId,
    address: shiptToAddress
  });
  
  return checkout;
}


  

export { fetchProducts, addToCart, removeFromCart, getCheckoutUrl, getCart, newCart };