
const CartButton = ({addProductToCart, activeCheckout}) => {
    return (
        <div className="cart-button">
            <button
                id="rc-add-to-cart"
                disabled={!activeCheckout}
                onClick={() => addProductToCart()}
                className="btn btn-primary">Add to Cart</button>
        </div>
    );
}

export default CartButton;